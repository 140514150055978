<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20.548 14.411"
    aria-hidden="true"
  >
    <path
      id="Path_164"
      data-name="Path 164"
      d="M37.348,34.778A4.472,4.472,0,0,0,32.87,30.3H21.278A4.472,4.472,0,0,0,16.8,34.778v5.455a4.472,4.472,0,0,0,4.478,4.478H32.87a4.472,4.472,0,0,0,4.478-4.478Zm-7.319,3-4.614,2.546c-.2.114-.386-.045-.386-.273V34.846c0-.227.182-.386.386-.273l4.66,2.659A.321.321,0,0,1,30.029,37.778Z"
      transform="translate(-16.8 -30.3)"
      fill="currentColor"
      class="transition-colors duration-150"
    />
  </svg>
</template>
